import { useScrollEntryExitParallax } from '@hooks/parallax/useScrollEntryExitParallax';
import { IFormData } from '@interfaces/contactInterfaces';
import { IEmailResponse } from '@interfaces/emailInterfaces';
import { sendEmail } from '@services/email/emailServices';
import { cleanInput, isValidEmail } from '@utils/formUtils';
import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './contact.module.css';

enum AnimationState {
    None = '',
    FadeOut = 'fadeOut',
    FadeIn = 'fadeIn',
    ShowThankYou = 'showThankYou',
    FadeThankYou = 'fadeThankYou',
}

const Contact: React.FC = () => {
    const { t } = useTranslation();
    const contactRef = useRef<HTMLDivElement>(null);
    const [formData, setFormData] = useState<IFormData>({
        name: '',
        email: '',
        purpose: '',
        message: '',
    });
    const [animationState, setAnimationState] = useState<AnimationState>(
        AnimationState.None,
    );
    const [typingMessage, setTypingMessage] = useState<string>('');
    const [submitted, setSubmitted] = useState<boolean>(false);
    const { offset: headingOffset, opacity: headingOpacity } =
        useScrollEntryExitParallax(contactRef, 880, 280, -800, 300, -1);

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const target = event.target;
            const { name, value: rawValue } = target;
            const value = cleanInput(rawValue, name);

            setFormData((prev) => ({ ...prev, [name]: value }));

            if (name === 'message' && target instanceof HTMLTextAreaElement) {
                target.style.height = 'auto';
                const maxHeight = 20 * 20;
                target.style.height = `${Math.min(target.scrollHeight, maxHeight)}px`;
            }
        },
        [],
    );

    const handleSubmit = useCallback(
        async (event: React.FormEvent) => {
            event.preventDefault();
            if (
                !formData.name.trim() ||
                !formData.email.trim() ||
                !formData.message.trim()
            ) {
                alert(t('error.allFieldsNotFilled'));
                return;
            }
            if (!isValidEmail(formData.email)) {
                alert(t('error.invalidEmailAdress'));
                return;
            }
            setAnimationState(AnimationState.FadeOut);
            setSubmitted(true);

            try {
                const response: IEmailResponse = await sendEmail(formData);
                console.log(response);
                setSubmitted(true);
            } catch (error) {
                console.error('Failed to send email:', error);
            }
        },
        [formData, t],
    );

    const handleAnimationEnd = useCallback(() => {
        switch (animationState) {
            case AnimationState.FadeOut:
                setFormData({ name: '', email: '', purpose: '', message: '' });
                setTypingMessage('');
                setAnimationState(AnimationState.ShowThankYou);
                break;
            case AnimationState.ShowThankYou:
                startTypingEffect(t('contact.thankYouMessage'));
                break;
            case AnimationState.FadeThankYou:
                setAnimationState(AnimationState.FadeIn);
                break;
            case AnimationState.FadeIn:
                setSubmitted(false);
                setAnimationState(AnimationState.None);
                break;
        }
    }, [animationState, t]);

    const startTypingEffect = (message: string) => {
        setTypingMessage('');
        let index = 0;
        const speed = 70;

        const typeNextChar = () => {
            if (index < message.length) {
                const newChar = message[index];
                setTypingMessage((prev) => prev + newChar);
                index++;
                setTimeout(typeNextChar, speed);
            } else {
                setTimeout(
                    () => setAnimationState(AnimationState.FadeThankYou),
                    2000,
                );
            }
        };
        setTimeout(typeNextChar, speed);
    };

    return (
        <section
            id="contact"
            ref={contactRef}
            className={styles.contactContainer}
            onAnimationEnd={handleAnimationEnd}
        >
            {animationState === AnimationState.ShowThankYou && submitted ? (
                <div className={`${styles.contactForm} ${styles.showThankYou}`}>
                    <h2 className={styles.thankYouMessage}>{typingMessage}</h2>
                </div>
            ) : animationState === AnimationState.FadeThankYou ? (
                <div
                    className={`${styles.contactForm} ${styles.fadeThankYou}`}
                    style={{ opacity: 0 }}
                >
                    <h2 className={styles.thankYouMessage}>{typingMessage}</h2>
                </div>
            ) : (
                <div
                    className={`${styles.contactForm} ${styles[animationState]}`}
                    aria-live="polite"
                >
                    <h2
                        style={{
                            transform: `translateX(${headingOffset.toString()}px)`,
                            opacity: headingOpacity.toString(),
                        }}
                    >
                        {t('contact.heading')}
                    </h2>
                    <form
                        className={styles.formAligned}
                        onSubmit={handleSubmit}
                        noValidate
                        aria-label="Contact form"
                    >
                        <div className={styles.inputGroup}>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder={t('contact.namePlaceholder')}
                                value={formData.name}
                                onChange={handleChange}
                                required
                                autoComplete="name"
                                className={styles.nameInput}
                            />
                            <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder={t('contact.emailPlaceholder')}
                                value={formData.email}
                                onChange={handleChange}
                                required
                                autoComplete="email"
                                className={styles.emailInput}
                            />
                        </div>
                        <div className={styles.inputGroup}>
                            <input
                                type="text"
                                id="purpose"
                                name="purpose"
                                placeholder={t('contact.purposePlaceholder')}
                                value={formData.purpose}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className={styles.inputGroup}>
                            <textarea
                                id="message"
                                name="message"
                                placeholder={t('contact.messagePlaceholder')}
                                value={formData.message}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <button type="submit" className={styles.submitButton}>
                            {t('contact.buttonText')}
                        </button>
                    </form>
                </div>
            )}
        </section>
    );
};

const ContactComponent = React.memo(Contact);
export default ContactComponent;
