import { RefObject, useEffect, useState } from 'react';

export const useScrollEntryExitParallax = (
    ref: RefObject<HTMLElement>,
    start: number,
    stop: number,
    resume: number,
    range: number,
    initialDirection: number,
) => {
    const [style, setStyle] = useState({
        offset: range * initialDirection,
        opacity: 0,
    });

    useEffect(() => {
        const handleScroll = () => {
            if (ref.current) {
                const rect = ref.current.getBoundingClientRect();
                const elementTop = rect.top;

                let newOffset, newOpacity;

                if (elementTop > start) {
                    newOffset = range * initialDirection;
                    newOpacity = 0;
                } else if (elementTop <= start && elementTop > stop) {
                    const progress = (start - elementTop) / (start - stop);
                    newOffset =
                        range * initialDirection -
                        progress * range * initialDirection;
                    newOpacity = progress / 1.3;
                } else if (elementTop <= stop && elementTop > resume) {
                    newOffset = 0;
                    newOpacity = 1;
                } else {
                    const progress = (resume - elementTop) / (resume - stop);
                    newOffset = progress * range * -initialDirection;
                    newOpacity = 0.5 + progress;
                    // console.log("opacity: ", newOpacity);
                }

                setStyle({
                    offset: newOffset,
                    opacity: newOpacity,
                });
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [ref, start, stop, resume, range, initialDirection]);

    return style;
};
