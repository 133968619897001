import { IHomeImage } from '@interfaces/sanityInterfaces';
import { client } from '@utils/sanityUtils';

export async function fetchHomeImage(): Promise<{
    url: string;
    alt: string;
    width: number;
    height: number;
}> {
    const query = `*[_type == "homeImage"][0]{
        image{
            asset->{
                _id,
                url,
                metadata {
                    dimensions {
                        width,
                        height
                    }
                }
            }
        },
        alt
    }`;

    try {
        const data: IHomeImage | null = await client.fetch(query);
        const url = data?.image?.asset?.url;
        const alt = data?.alt ?? "Display of Josephine's photography work";
        const width = data?.image?.asset?.metadata?.dimensions?.width;
        const height = data?.image?.asset?.metadata?.dimensions?.height;

        if (!url || !width || !height) {
            throw new Error('Image URL, width, or height not found.');
        }

        return { url, alt, width, height };
    } catch (error) {
        console.error('Failed to fetch home image', error);
        return {
            url: '',
            alt: 'Failed to fetch home image',
            width: 0,
            height: 0,
        };
    }
}
