import BrandLogo from '@shared/brandLogo/brandLogo';
import HamburgerMenu from '@shared/hamburgerMenu/hamburgerMenu';
import LanguageToggleButton from '@shared/languageToggleButton/languageToggleButton';
import NavLink from '@shared/navLink/navLink';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './navbar.module.css';

const Navbar: React.FC = () => {
    const { t } = useTranslation();

    return (
        <nav className={styles.navbarContainer}>
            <BrandLogo reload animate highlight />
            <ul className={styles.desktopMenu}>
                {' '}
                <li>
                    <NavLink href="/#works" label={t('navigation.works')} />
                </li>
                <li>
                    <NavLink href="/#about" label={t('navigation.about')} />
                </li>
                <li>
                    <NavLink href="/#contact" label={t('navigation.contact')} />
                </li>
                <li>
                    <LanguageToggleButton />
                </li>{' '}
            </ul>
            <div className={styles.mobileMenu}>
                <HamburgerMenu />
            </div>
        </nav>
    );
};

export default Navbar;
