import Link from 'next/link';
import React, { useCallback, useEffect, useState } from 'react';

import styles from './brandLogo.module.css';

interface BrandLogoProps {
    reload?: boolean;
    animate?: boolean;
    highlight?: boolean;
}

const BrandLogo: React.FC<BrandLogoProps> = ({
    reload = false,
    animate = false,
    highlight = false,
}) => {
    const [visibleLetters, setVisibleLetters] = useState<boolean[]>(
        Array(13).fill(false),
    );
    const logoText = 'JOSEPHINE NOA BAYLE';
    const logoTextMobile = 'JNB';

    useEffect(() => {
        if (animate) {
            const timeouts: ReturnType<typeof setTimeout>[] = [];

            logoText.split('').forEach((_, index) => {
                timeouts.push(
                    setTimeout(() => {
                        setVisibleLetters((prev) => {
                            const newState = [...prev];
                            newState[index] = true;
                            return newState;
                        });
                    }, 100 * index),
                );
            });

            return () => {
                timeouts.forEach(clearTimeout);
            };
        }
    }, [animate, logoText]);

    const reloadPage = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            if (reload) {
                e.preventDefault();
                window.location.href = '/';
            }
        },
        [reload],
    );

    return (
        <div className={styles.brandLogo}>
            <Link href="/" passHref>
                <button onClick={reload ? reloadPage : undefined}>
                    <span className={styles.desktopLogo}>
                        {logoText.split('').map((letter, index) => (
                            <span
                                key={index}
                                className={
                                    visibleLetters[index]
                                        ? styles.visible
                                        : styles.hidden
                                }
                            >
                                {letter}
                            </span>
                        ))}
                    </span>
                    <span className={styles.mobileLogo}>
                        {logoTextMobile.split('').map((letter, index) => (
                            <span
                                key={index}
                                className={
                                    visibleLetters[index]
                                        ? styles.visible
                                        : styles.hidden
                                }
                            >
                                {letter}
                            </span>
                        ))}
                    </span>
                    {highlight && (
                        <div className={styles.navbarBrandHighlight}></div>
                    )}
                </button>
            </Link>
        </div>
    );
};

export default BrandLogo;
