import React from 'react';

import styles from './progressCircle.module.css';

interface ProgressCircleProps {
    progress: number;
}

const ProgressCircle: React.FC<ProgressCircleProps> = ({ progress }) => {
    const pathLength = 314.159;
    return (
        <svg
            className={styles.progressCircle}
            width="100%"
            height="100%"
            viewBox="-1 -1 102 102"
        >
            <path
                d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                style={{
                    strokeDasharray: `${pathLength} ${pathLength}`,
                    strokeDashoffset: progress,
                }}
            />
            <path
                d="M51 35 L32 60 M51 35 L70 60"
                stroke="#fff"
                strokeWidth={7}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ProgressCircle;
