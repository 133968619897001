import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './languageToggleButton.module.css';

const LanguageToggleButton: React.FC = memo(() => {
    const { i18n } = useTranslation();

    const toggleLanguage = () => {
        const newLang = i18n.language === 'en' ? 'fr' : 'en';
        i18n.changeLanguage(newLang);
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            toggleLanguage();
        }
    };

    const isEnglish = i18n.language === 'en';
    const highlightStyle = {
        transform: `translateX(${isEnglish ? 0 : 100}%)`,
    };

    return (
        <div
            className={styles.languageToggleContainer}
            onClick={toggleLanguage}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex={0}
            aria-label="Toggle Language"
        >
            <div
                className={styles.languageToggleHighlight}
                style={highlightStyle}
            ></div>
            <div
                className={`${styles.languageOption} ${isEnglish ? styles.selected : ''}`}
            >
                <span>EN</span>
            </div>
            <div
                className={`${styles.languageOption} ${!isEnglish ? styles.selected : ''}`}
            >
                <span>FR</span>
            </div>
        </div>
    );
});

LanguageToggleButton.displayName = 'LanguageToggleButton';

export default LanguageToggleButton;
