import { IEmailResponse } from '@interfaces/emailInterfaces';
import axios from 'axios';

export const sendEmail = async (emailData: {
    name: string;
    email: string;
    purpose: string;
    message: string;
}): Promise<IEmailResponse> => {
    try {
        const response = await axios.post<IEmailResponse>(
            '/api/send-email',
            emailData,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );
        return response.data;
    } catch (error) {
        console.error('Error sending email:', error);
        throw error;
    }
};
