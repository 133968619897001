import ProgressCircle from '@hooks/progressCircle/progressCircle';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import styles from './backToTopButton.module.css';

const BackToTopButton: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [progress, setProgress] = useState(0);
    const animationFrameRef = useRef<number | null>(null);

    const handleScroll = useCallback(() => {
        const pathLength = 314.159;
        const scrollDistance = document.body.scrollHeight - window.innerHeight;
        const scrolled = window.scrollY;
        const scrollProgress = scrolled / scrollDistance;

        setProgress(pathLength * (1 - scrollProgress));
        setIsVisible(scrolled > 500);
    }, []);

    useEffect(() => {
        const onScroll = () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
            animationFrameRef.current = requestAnimationFrame(handleScroll);
        };

        window.addEventListener('scroll', onScroll);
        return () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
            window.removeEventListener('scroll', onScroll);
        };
    }, [handleScroll]);

    const scrollToTop = useCallback(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const handleKeyPress = useCallback(
        (event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.key === 'Enter' || event.key === ' ') {
                scrollToTop();
            }
        },
        [scrollToTop],
    );

    return (
        <div
            className={`${styles.progressWrap} ${isVisible ? styles.activeProgress : ''}`}
            onClick={scrollToTop}
            onKeyDown={handleKeyPress}
            role="button"
            tabIndex={0}
            aria-label="Back to top"
        >
            <ProgressCircle progress={progress} />
        </div>
    );
};

export default BackToTopButton;
