import { useScrollEntryExitParallax } from '@hooks/parallax/useScrollEntryExitParallax';
import profileImage from '@public/profile.jpg';
import NavLink from '@shared/navLink/navLink';
import SocialIcon from '@shared/socialIcon/socialIcon';
import Image from 'next/image';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './about.module.css';

const About: React.FC = () => {
    const { t } = useTranslation();
    const aboutRef = useRef<HTMLDivElement>(null);
    const { offset: introOffset, opacity: introOpacity } =
        useScrollEntryExitParallax(aboutRef, 880, 280, -140, 300, -1);
    const { offset: locOffset, opacity: locOpacity } =
        useScrollEntryExitParallax(aboutRef, 880, 250, -170, 300, -1);

    return (
        <section
            id="about"
            ref={aboutRef}
            className={styles.aboutContainer}
            aria-labelledby="about-heading"
        >
            <div className={styles.leftSide}>
                <h2
                    className={`${styles.introduction} ${styles.textAligned} ${styles.h2}`}
                    style={{
                        transform: `translateX(${introOffset.toString()}px)`,
                        opacity: introOpacity.toString(),
                    }}
                >
                    {t('about.introduction')}
                </h2>
                <h2
                    className={`${styles.textAligned} ${styles.h2}`}
                    style={{
                        transform: `translateX(${locOffset.toString()}px)`,
                        opacity: locOpacity,
                    }}
                >
                    {t('about.location')}
                </h2>
            </div>
            <div className={styles.rightSide}>
                <Image
                    alt="Josephine portrait picture"
                    src={profileImage}
                    className={styles.profilePicture}
                    placeholder="blur"
                    width={500}
                    height={500}
                />
                <article className={styles.aboutSection}>
                    <h3 className={`${styles.textAligned} ${styles.h3}`}>
                        {t('about.title')}
                    </h3>
                    <p>{t('about.bio')}</p>
                </article>
                <article className={styles.contactSection}>
                    <h3 className={`${styles.textAligned} ${styles.h3}`}>
                        {t('about.contactTitle')}
                    </h3>
                    <div className={styles.contactDetails}>
                        <a
                            href="mailto:josephinenoabayle@gmail.com"
                            className={styles.emailLink}
                        >
                            josephinenoabayle@gmail.com
                        </a>
                        <ul className={styles.socialIcons}>
                            <SocialIcon
                                href="https://www.instagram.com/noabayle/"
                                iconName="Instagram"
                                ariaLabel="Visit Instagram profile"
                                size="medium"
                            />
                            <SocialIcon
                                href="https://www.behance.net/josephinenoabayle/"
                                iconName="Behance"
                                ariaLabel="Visit Behance profile"
                                size="medium"
                            />
                        </ul>
                        <NavLink
                            href="#contact"
                            label={t('about.contactLink')}
                            className={styles.contactMeLink}
                        />
                    </div>
                </article>
            </div>
        </section>
    );
};

export default About;
