import { use3DParallax } from '@hooks/parallax/use3DParallax';
import { useScrollFadeEffect } from '@hooks/parallax/useScrollFadeEffect';
import { useScrollParallax } from '@hooks/parallax/useScrollParallax';
import { useImage } from '@hooks/useImage/useImage';
import { fetchHomeImage } from '@services/sanity/sanityServices';
import Image from 'next/image';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './home.module.css';

const Home: React.FC = () => {
    const { t } = useTranslation();
    const homeRef = useRef<HTMLElement>(null);
    const opacity = useScrollFadeEffect(1800, homeRef);
    const firstNameOffset = useScrollParallax(0.35, -1);
    const lastNameOffset = useScrollParallax(0.3, 1);
    const photographerOffset = useScrollParallax(0.6, -1);
    const { calcTranslate: calcImageTranslate } = use3DParallax(220);
    const { calcTranslate: calcTextTranslate } = use3DParallax(100);
    const homeImage = useImage(fetchHomeImage);

    return (
        <section
            ref={homeRef}
            className={styles.homeContainer}
            aria-labelledby="home-heading"
            style={{ opacity }}
        >
            <div
                className={styles.homeImageContainer}
                style={{
                    transform: `translateX(${calcImageTranslate('x').toString()}px) translateY(${calcImageTranslate('y').toString()}px)`,
                }}
            >
                {homeImage?.url && (
                    <Image
                        src={homeImage.url}
                        alt={homeImage.alt || 'Home Image'}
                        width={homeImage.width}
                        height={homeImage.height}
                        priority
                        sizes="(max-width: 320px) 280px, (max-width: 480px) 440px, (max-width: 768px) 728px, 100vw"
                        placeholder="blur"
                        blurDataURL={homeImage.url}
                    />
                )}
            </div>
            <div
                className={styles.homeTextContainer}
                style={{
                    transform: `translateX(${calcTextTranslate('x').toString()}px) translateY(${calcTextTranslate('y').toString()}px)`,
                }}
            >
                <h1
                    id="home-heading"
                    className={styles.homeFirstname}
                    style={{
                        transform: `translateX(${firstNameOffset.toString()}px)`,
                    }}
                >
                    JOSEPHINE
                </h1>
                <div className={styles.homeDetailsContainer}>
                    <p
                        className={styles.homePhotographer}
                        style={{
                            transform: `translateX(${photographerOffset.toString()}px)`,
                        }}
                    >
                        {t('home.role')}
                    </p>
                    <h2
                        className={styles.homeLastname}
                        style={{
                            transform: `translateX(${lastNameOffset.toString()}px)`,
                        }}
                    >
                        NOA BAYLE
                    </h2>
                </div>
            </div>
        </section>
    );
};

export default Home;
