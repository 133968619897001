import { RefObject, useEffect, useState } from 'react';

export const useScrollFadeEffect = (
    fadeDistance: number,
    ref: RefObject<HTMLElement>,
): number => {
    const [opacity, setOpacity] = useState(1);

    useEffect(() => {
        const handleScroll = () => {
            if (ref.current) {
                const rect = ref.current.getBoundingClientRect();
                const visible =
                    rect.top < window.innerHeight && rect.bottom >= 0;
                const newOpacity = visible
                    ? Math.max(
                          1 - (window.scrollY - rect.top) / fadeDistance,
                          0,
                      )
                    : 1;
                setOpacity(newOpacity);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [fadeDistance, ref]);

    return opacity;
};
