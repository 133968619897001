import { useEffect, useState } from 'react';

export const use3DParallax = (intensity = 10) => {
    const [coords, setCoords] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleMouseMove = (event: MouseEvent) => {
            const x = (event.clientX - window.innerWidth / 2) / intensity;
            const y = (event.clientY - window.innerHeight / 2) / intensity;
            setCoords({ x, y });
        };

        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, [intensity]);

    const calcTranslate = (axis: 'x' | 'y') => {
        return coords[axis];
    };

    return { calcTranslate };
};
