export const cleanInput = (input: string, fieldType: string): string => {
    switch (fieldType) {
        case 'name':
            return input.replace(/[^a-zA-Z ]/g, '').slice(0, 75);
        case 'email':
            return input.replace(/[^\w@.-]/g, '').slice(0, 100);
        default:
            return input.replace(
                /[^\w\s&é~"#'{[(-|è`_ç^à@)]°+=}$£¤ù%*µ!§:;.,?<>]/g,
                '',
            );
    }
};

export const isValidEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};
