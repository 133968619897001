'use client';

import '@app/globals.css';
import '@i18n/i18n';

import About from '@components/about/about';
import Contact from '@components/contact/contact';
import Home from '@components/home/home';
import Works from '@components/works/works';
import BackToTopButton from '@shared/backToTopButton/backToTopButton';
import Footer from '@shared/footer/footer';
import Navbar from '@shared/navbar/navbar';
import Head from 'next/head';

const App: React.FC = () => {
    return (
        <>
            <Head>
                <title>Josephine Noa Bayle</title>
            </Head>
            <div className="App">
                <Navbar />
                <main>
                    <Home />
                    <Works />
                    <About />
                    <Contact />
                    <Footer />
                    <BackToTopButton />
                </main>
            </div>
        </>
    );
};

export default App;
