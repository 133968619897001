import React, { useEffect, useState } from 'react';

import styles from './works.module.css';

const Works: React.FC = () => {
    const [dots, setDots] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            setDots((prevDots) => {
                return prevDots.length === 3 ? '' : prevDots + '.';
            });
        }, 500);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div id="works" className={styles.worksContainer}>
            Works section build in progress{dots}
        </div>
    );
};

export default Works;
