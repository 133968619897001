import { ISanityEnvironment } from '@interfaces/sanityInterfaces';
import { createClient } from '@sanity/client';

const sanityEnvironment: ISanityEnvironment = {
    projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID!,
    dataset: process.env.NEXT_PUBLIC_SANITY_DATASET!,
    apiVersion: process.env.NEXT_PUBLIC_SANITY_API_VERSION!,
};

if (
    !sanityEnvironment.projectId ||
    !sanityEnvironment.dataset ||
    !sanityEnvironment.apiVersion
) {
    throw new Error('Sanity environment variables not provided.');
}

export const client = createClient({
    projectId: sanityEnvironment.projectId,
    dataset: sanityEnvironment.dataset,
    apiVersion: sanityEnvironment.apiVersion,
    useCdn: false,
});
